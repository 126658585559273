import React from "react";

const Input = ({
  label,
  type = "text",
  id,
  name,
  placeholder,
  required = false,
  disabled = false,
  error,
  ...props
}) => {
  const hasError = !!error;

  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className="block text-base text-[#323539] font-bold mb-2"
        >
          {label}
        </label>
      )}
      <input
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        className={`shadow appearance-none border-[#E5E5E7] border rounded-[128px] w-full py-[14px] px-4 text-base text-[#858C95] focus:outline-none focus:shadow-outline ${
          hasError ? "border-[#EF4444]" : ""
        }`}
        {...props}
      />
      {hasError && <p className="text-base text-[#EF4444] mt-2">{error}</p>}
    </div>
  );
};

export default Input;
