import React, { useEffect, useState } from "react";
import { Field } from "redux-form";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// Local component imports
import Modal from "../../boxes/DialogModal.js";
import FulfillmentDetails from "../../FulfillmentDetails.js";
import FulfillmentBase from "../../FulfillmentBase.js";
import ConfirmPharmacySelection from "../../ConfirmPharmacySelection.js";
import FulfillmentPharmacyList from "../../FulfillmentPharmacyList.js";
import DeliveryStatus from "../../DeliveryStatus.js";
import FulFillmentSuccess from "../../FulFillmentSuccess.js";
import FulfilllmentDeleted from "../../FulfilllmentDeleted.js";

// Utility and service imports
import { ENDPOINTS } from "../../../services/ApiService.js";

// Loading and notification imports
import LoadingScreen from "../../../components/LoadingScreen.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// User actions import
import * as userActions from "../../../actions/user.js";

const notify = (e) =>
  toast.error(e?.message, {
    position: "top-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

const FulfillmentModal = ({
  isOpen,
  onClose,
  service,
  consultationId,
  prescriptionId,
  ...props
}) => {
  const [header, setHeader] = useState();
  const [formFields, setFormFields] = useState({});
  const [modalContent, setModalContent] = useState(null);
  const [deliveryMethod, setDeliveryMethod] = useState("pickUp");
  const { pharmacies, prescriptions, user, dispatch, profile } = props;

  const filteredOptions = (pharmacies, filterValue) => {
    const optionValues = [];
    pharmacies.forEach((pharmacy, index) => {
      const currentfilterValue = pharmacy[filterValue];
      if (!optionValues.includes(currentfilterValue)) {
        optionValues.push(currentfilterValue);
      }
    });
    return optionValues;
  };

  useEffect(() => {
    dispatch(userActions.getProfileIfNeeded(user.credentials.accessToken));
    // props.onClose(MODALS.consultationFulfillment)
  }, []);

  const handlefulfillmentError = (e) => {
    if (
      (e?.type || "").toLowerCase() === "model not found" ||
      e?.code === 404
    ) {
      setModalContent("deletedPrescription");
    } else {
      notify(e);
    }
  };

  const handledeliveryMethodChange = (e) => {
    setDeliveryMethod(e.target.value);
  };

  const populateFulfilmentData = (val) => {
    setFormFields({ ...formFields, ...val });
  };

  const closeModal = () => {
    onClose();
    setModalContent(null);
  };

  const handleSetFormField = (val) => {
    setFormFields({ ...formFields, ...val });
  };

  const hasDelivery = (state) => {
    const hasDelivery = pharmacies
      .filter((val) => val.state === state)
      .some((pharm) => pharm.has_home_delivery === "Yes");
    populateFulfilmentData({ hasDelivery });
  };

  const handleConfirmDelivery = () => {
    const { deliveryMethod, selectedPharmacy, address, phoneNumber } =
      formFields;
    let payload;
    if (deliveryMethod === "pickUp") {
      const {
        provider_id,
        name,
        address: pharmacyAddress,
        code,
      } = selectedPharmacy;
      payload = {
        provider_id,
        phone: phoneNumber,
        address,
        metadata: {
          name,
          address: pharmacyAddress,
          pharmacy_code: code == null ? "N/A" : code,
        },
      };
      setModalContent("loading");

      service
        .put(
          ENDPOINTS.consultations.prescriptionsPickup(
            consultationId,
            prescriptionId
          ),
          payload
        )
        .then(() => {
          setModalContent("fulFillmentSuccess");
        })
        .catch((e) => {
          handlefulfillmentError(e);

          // setModalContent("fulfillmentDetails");
        });
    } else {
      payload = {
        state_id: pharmacies.find((val) => val.state === formFields.state)[
          "state_object"
        ].id,
        phone_number: phoneNumber,
        address,
      };
      setModalContent("loading");
      service
        .post(
          ENDPOINTS.consultations.prescriptionsDelivery(
            consultationId,
            prescriptionId
          ),
          payload
        )
        .then(() => {
          setModalContent("fulFillmentSuccess");
        })
        .catch((e) => {
          handlefulfillmentError(e);

          // setModalContent("fulfillmentDetails");
        });
    }
  };

  if (prescriptions && prescriptions.delivery_option) {
    return (
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        header={header}
        backArrow={false}
      >
        <DeliveryStatus
          setHeader={setHeader}
          prescriptions={prescriptions}
          profile={formFields}
          deliveryMethod={deliveryMethod}
        />
      </Modal>
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {modalContent == "confirmPharmacySelection" ? (
        <Modal
          isOpen={isOpen}
          onClose={closeModal}
          header={header}
          backArrow={false}
        >
          <ConfirmPharmacySelection
            setHeader={setHeader}
            setFormFields={setFormFields}
            formFields={formFields}
            profile={formFields}
            selectedPharmacy={formFields.selectedPharmacy}
            populateFulfilmentData={populateFulfilmentData}
            setModalContent={setModalContent}
            handleConfirmDelivery={handleConfirmDelivery}
            deliveryMethod={deliveryMethod}
            onNoClicked={() => setModalContent("fulfillmentDetails")}
          />
        </Modal>
      ) : modalContent == "fulfillmentPharmacyList" ? (
        <Modal
          isOpen={isOpen}
          onClose={closeModal}
          header={header}
          backArrow={true}
          onBackClicked={() => setModalContent("fulfillmentDetails")}
        >
          <FulfillmentPharmacyList
            setHeader={setHeader}
            setFormFields={handleSetFormField}
            pharmacies={
              pharmacies.length &&
              pharmacies
                .filter(
                  (val) =>
                    val.state_object &&
                    val.state == formFields.state &&
                    val.state
                )
                .sort((a, b) => {
                  // First, sort by the "is_recommended" property (descending order)
                  const aRecommended = a.is_recommended || false;
                  const bRecommended = b.is_recommended || false;
                  if (bRecommended && !aRecommended) {
                    return 1; // b should come before a
                  } else if (!bRecommended && aRecommended) {
                    return -1; // a should come before b
                  }

                  // If the "is_recommended" properties are equal, sort by the "name" property (ascending order)
                  const nameA = a.name.toLowerCase();
                  const nameB = b.name.toLowerCase();
                  if (nameA < nameB) {
                    return -1; // a should come before b
                  } else if (nameA > nameB) {
                    return 1; // b should come before a
                  } else {
                    return 0; // the order should remain the same
                  }
                })
            }
            formFields={formFields}
            deliveryMethod={deliveryMethod}
            setModalContent={setModalContent}
            populateFulfilmentData={populateFulfilmentData}
          />
        </Modal>
      ) : modalContent === "fulFillmentSuccess" ? (
        <Modal
          isOpen={isOpen}
          onClose={closeModal}
          header={header}
          backArrow={true}
          onBackClicked={onClose}
          showHeader={false}
        >
          <FulFillmentSuccess
            onClick={closeModal}
            deliveryMethod={deliveryMethod}
          />
        </Modal>
      ) : modalContent === "loading" ? (
        <LoadingScreen />
      ) : modalContent === "fulfillmentDetails" ? (
        <Modal
          isOpen={isOpen}
          onClose={closeModal}
          header={header}
          backArrow={true}
          onBackClicked={() => {
            closeModal();
          }}
        >
          <FulfillmentDetails
            setHeader={setHeader}
            states={filteredOptions(pharmacies, "state").filter(
              (val) => val !== "Federal Capital Territory"
            )}
            setFormFields={setFormFields}
            Field={Field}
            state={formFields.state}
            hasDelivery={hasDelivery}
            homeDelivery={formFields.hasDelivery}
            deliveryMethod={deliveryMethod}
            handledeliveryMethodChange={handledeliveryMethodChange}
            profile={profile}
            populateFulfilmentData={populateFulfilmentData}
            setModalContent={setModalContent}
          />
        </Modal>
      ) : modalContent === "deletedPrescription" ? (
        <Modal isOpen={isOpen} showHeader={false}>
          <FulfilllmentDeleted onClick={closeModal} />
        </Modal>
      ) : (
        <Modal
          isOpen={isOpen}
          onClose={closeModal}
          header={header}
          backArrow={true}
          onBackClicked={onClose}
        >
          <FulfillmentBase
            setHeader={setHeader}
            onClose={closeModal}
            setModalContent={setModalContent}
            prescriptions={prescriptions}
          />
        </Modal>
      )}
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  const profile = state.user.meta.profile || {};
  const user = state.session.user;
  const prescriptionId = state.consultation.prescriptionId;
  const prescriptions = state.consultation.prescriptions;
  return { profile, user, prescriptionId, prescriptions };
}

export default withRouter(connect(mapStateToProps)(FulfillmentModal));
