import React from "react";
import Info from "../img/Info.png";

const RFCDisclaimer = () => {
  return (
    <div
      style={{
        backgroundColor: "#FFFAF2",
        padding: 5,
        marginBottom: 10,
        marginTop: 10,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        gap: 5,
      }}
    >
      <div>
        <img src={Info} alt="" width={40} />
      </div>
      <div>
        <p>
          Prescription is normally ready for pick up within 2 business hours.
          You can pick it up at any time during the opening hours after
          receiving the code.
        </p>
      </div>
    </div>
  );
};

export default RFCDisclaimer;
