import React from "react";
import { Link, withRouter } from "react-router-dom";
import LOGO from "../img/logo.svg";

class PageNotFound extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { history } = this.props;

    return (
      <div className="container" style={{ paddingBottom: "50px" }}>
        <div style={{ paddingTop: "150px", textAlign: "center" }}>
          <Link to={"/"}>
            <img src={LOGO} alt="Reliance HMO's Logo" />
          </Link>
        </div>
        <div
          style={{
            textAlign: "center",
            fontSize: "200px",
            marginBottom: "10px",
            color: "#c5c4c4",
          }}
        >
          404
        </div>
        <div style={{ textAlign: "center", fontSize: "16px" }}>
          Oops!...Nothing to see here
          <br />
          <br />
          <Link to={"#"} onClick={history.goBack}>
            Go Back
          </Link>
        </div>
      </div>
    );
  }
}

export default withRouter(PageNotFound);
