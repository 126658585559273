import React from "react";
import { Heading } from "@reliance/design-system";
import PharmacyItem from "../PharmacyItem";
import RFCDisclaimer from "../RFCDisclaimer";

const FulfilmentRFCPickup = ({ pharmacy, handleSelectedPharmacy }) => {
  return (
    <div>
      <Heading level={"h1"}>Prescription Pickup</Heading>
      <RFCDisclaimer />
      {pharmacy.map((pharm, index) => (
        <PharmacyItem
          key={index}
          pharmacy={pharm}
          onClick={() =>
            handleSelectedPharmacy({ ...pharm, isRecommended: true })
          }
        />
      ))}
    </div>
  );
};

export default FulfilmentRFCPickup;
