import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import { configureStore } from "./store/configureStore";
import emptyStore from "./store/emptyStore";
import register from "./registerServiceWorker";
import { sessionService } from "redux-react-session";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { FeatureFlagProvider } from "./context/FeatureFlagsContext";

import "./reliance-tailwind.css";

export const store = configureStore(emptyStore);

sessionService.initSessionService(store, { driver: "LOCALSTORAGE" });

ReactDOM.render(
  <Provider store={store}>
    <FeatureFlagProvider>
      <App />
    </FeatureFlagProvider>
  </Provider>,
  document.getElementById("root")
);

register();
