import React, { useEffect } from "react";
import PharmacyItem from "./PharmacyItem";
import ProfileAddress from "./ProfileAddress";
import moment from "moment";
import DrugsContainer from "../containers/DrugsContainer";
import { Heading } from "@reliance/design-system";

const p = {
  color: "#333",
  fontSize: "14px",
  fontFamily: "Avenir LT Std",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "20px",
  letterSpacing: "0.15px",
};

const fulfilledMessage = (dispatchStatus, deliveryOption) => {
  const deliveryMethod = dispatchStatus.toLowerCase();
  if (deliveryMethod === "delivered" || deliveryMethod === "picked_up") {
    return deliveryOption.toLowerCase() === "homedelivery"
      ? "Your prescription has been successfully delivered. If you have any questions or concerns, please contact our support team for assistance"
      : "You've successfully picked up your prescription from the pharmacy. If you have any questions or concerns, please contact our support team for assistance";
  }
  return deliveryOption.toLowerCase() === "homedelivery"
    ? "Present this code to the dispatch rider and the prescribed drugs will be given to you"
    : "Present this code at your pick-up pharmacy, and the prescribed drugs will be given to you";
};

const isfFulfilled = (dispatchStatus) => {
  if (
    dispatchStatus.toLowerCase() === "delivered" ||
    dispatchStatus.toLowerCase() === "picked_up"
  ) {
    return true;
  }
  return false;
};

const DeliveredPrescription = ({ code, deliveryMethod, dispatchStatus }) => {
  return (
    <div
      className=""
      style={{
        display: "flex",
        padding: "16px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        alignSelf: "stretch",
        borderRadius: "4px",
        border: "1px solid var(--ui-06, #EEE)",
        background: " #FAFDFF",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "8px",
        }}
      >
        <p
          style={{
            color: "var(--primary-color-baby-blue, #107BC0)",
            fontSize: "20px",
            fontFamily: "Avenir LT Std",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "20px",
            letterSpacing: "0.15px",
          }}
        >
          {!isfFulfilled(dispatchStatus) && code}
        </p>
        <p
          style={{
            color: "var(--text-01, #212121)",
            fontSize: "12px",
            fontFamily: "Avenir LT Std",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
          }}
        >
          {fulfilledMessage(dispatchStatus, deliveryMethod)}
        </p>
      </div>
    </div>
  );
};

const DeliveryStatus = ({ setHeader, prescriptions, newFlow }) => {
  const prescriptionCreatedDate = moment(prescriptions?.created_at);

  const time = () => {
    if (prescriptionCreatedDate.hour() < 12) {
      return prescriptionCreatedDate.format("ddd, MMMM Do, YYYY");
    }
    return prescriptionCreatedDate.add(1, "days").format("ddd, MMMM Do, YYYY");
  };

  const prescriptionStatus = {
    PENDING: "PENDING",
    CANCELLED: "CANCELLED",
    DISPATCHED: "DISPATCHED",
    RECEIVED: "RECEIVED",
    CONFIRMED: "CONFIRMED",
    READY_FOR_PICKUP: "READY_FOR_PICKUP",
    PICKED_UP: "PICKED_UP",
    DELIVERED: "DELIVERED",
    DEACTIVATED: "DEACTIVATED",
  };

  // Function to check if the prescription status is past confirmation stage
  function isPrescriptionPastConfirmation(dispatchStatus) {
    const upperDispatchStatus = dispatchStatus.toUpperCase();
    return (
      upperDispatchStatus === prescriptionStatus.READY_FOR_PICKUP ||
      upperDispatchStatus === prescriptionStatus.DISPATCHED ||
      upperDispatchStatus === prescriptionStatus.PICKED_UP ||
      upperDispatchStatus === prescriptionStatus.DELIVERED
    );
  }

  useEffect(() => {
    setHeader("Prescriptions");
  }, []);
  return (
    <div
      style={{
        display: "flex",
        // width: "365px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
      }}
    >
      {newFlow && (
        <Heading level={"h1"} style={{ fontSize: 18 }}>
          Prescriptions
        </Heading>
      )}
      <div
        className=""
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        {!isfFulfilled(prescriptions.dispatch_status) && (
          <p style={p}>Prescription code</p>
        )}
        {isPrescriptionPastConfirmation(prescriptions.dispatch_status) && (
          <DeliveredPrescription
            code={prescriptions.code}
            deliveryMethod={prescriptions.delivery_option}
            dispatchStatus={prescriptions.dispatch_status}
          />
        )}
        {prescriptions.delivery_option.toLowerCase() === "homedelivery" &&
          prescriptions.dispatch_status !== "dispatched" && (
            <>
              <span style={p}>
                Your prescription will be delivered to your location on:
              </span>
              <span
                style={{
                  ...p,
                  color: "var(--primary-color-baby-blue, #107BC0)",
                }}
              >
                {time()}
              </span>
            </>
          )}
        {prescriptions.dispatch_status === "pending" && (
          <div
            className=""
            style={{
              display: "flex",
              padding: "16px",
              alignItems: "flex-start",
              gap: "16px",
              alignSelf: "stretch",
              borderRadius: "4px",
              background: "var(--ui-04, #F8F8F8)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "stretch",
              }}
            >
              {prescriptions.delivery_option.toLowerCase() === "homedelivery"
                ? "We will notify you and display the prescription code to receive drug from the dispatch rider here when your drugs has been successfully dispensed"
                : "We will notify you and display the prescription code to pick up your drug from the pharmacy here when your drugs has been successfully dispensed"}
            </div>
          </div>
        )}
        {prescriptions &&
          prescriptions.delivery_option.toLowerCase() === "homedelivery" && (
            <React.Fragment>
              <p style={p}>Contact details</p>
              {prescriptions.address ? (
                <ProfileAddress profile={prescriptions} />
              ) : (
                <p>Address not available at the momment, please check back.</p>
              )}
            </React.Fragment>
          )}
        {prescriptions &&
          prescriptions.delivery_option.toLowerCase() === "pickup" && (
            <React.Fragment>
              <p style={p}>Selected Pharmacy</p>
              <PharmacyItem
                pharmacy={{
                  ...prescriptions.provider,
                  delivery_option: "pickup",
                }}
              />
            </React.Fragment>
          )}
        {prescriptions && <DrugsContainer prescriptions={prescriptions} />}
      </div>
    </div>
  );
};

export default DeliveryStatus;
