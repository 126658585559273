import React from "react";
import bag from "../../src/img/Error2.png";
import {} from "@reliance/design-system";
import { Heading } from "@reliance/design-system";
import { Button } from "@reliance/design-system";

export default ({ onClick, error, ERRORTYPE, handleGoBack, tryAgain }) => {
  const { type, message } = error;
  console.log(error);
  console.log(message);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 10,
        marginBottom: 15,
        marginTop: 15,
      }}
    >
      <img src={bag} alt="" width={40} />

      {type === ERRORTYPE.deleted && (
        <>
          <Heading
            level={"h1"}
            style={{ textAlign: "center", marginBottom: 10, fontSize: 18 }}
          >
            Prescription Deleted
          </Heading>

          <div>
            The doctor has deleted this prescription, you can not proceed.
            Please chat them to learn why
          </div>

          <Button style={{ width: "80%" }} onClick={onClick}>
            Back to consultation
          </Button>
        </>
      )}
      {type === ERRORTYPE.other && (
        <>
          <Heading
            style={{ textAlign: "center", marginBottom: 10, fontSize: 18 }}
          >
            Something’s wrong
          </Heading>
          <div style={{ fontSize: 16 }}>
            {message ||
              "Ooops, we encountered an error and you will not be able to proceed with your prescriptions right nowx"}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
              width: "100%",
            }}
          >
            <Button
              variant={"outline"}
              onClick={handleGoBack}
              style={{ width: "48%", fontSize: 14, height: 35 }}
            >
              Cancel
            </Button>
            <Button
              onClick={tryAgain}
              style={{ width: "48%", fontSize: 14, height: 35 }}
            >
              Try Again
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
