import { Button } from "@reliance/design-system";
import { Heading } from "@reliance/design-system";
import React from "react";

const FulfilmentDelivery = ({ onClose, onProceed }) => {
  return (
    <div>
      <Heading level={"h1"}>Prescription Delivery</Heading>
      <div>Delivery details</div>
      <p>Where do you want to the prescription delivered</p>

      <div className="">
        <Button onClick={onClose} variant={"outline"}>
          Cancel
        </Button>
        <Button onClick={onProceed}>proceed</Button>
      </div>
    </div>
  );
};

export default FulfilmentDelivery;
