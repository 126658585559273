// import styled from "@emotion/styled";
import React from "react";
import PropTypes from "prop-types";
import Delivery from "./assets/Delivery.png";
import Pickup from "./assets/Pickup.png";
import Greater from "./assets/Greater.png";
import Check from "./assets/Check.png";
import "./cards.css";

const FulfilmentCard = ({
  children,
  deliveryType,
  options,
  recommended,
  onClick,
}) => {
  return (
    <div onClick={onClick}>
      {recommended && (
        <div className="card-recommended-container">Recommended</div>
      )}
      <div
        className="card-container"
        style={{ borderRadius: recommended ? "0px 0px 15px 15px" : "15px" }}
      >
        <div className="card-header-container">
          <img
            src={deliveryType === "delivery" ? Delivery : Pickup}
            alt={deliveryType}
            width={"40px"}
          />
          <div>
            <div className="card-title">
              {deliveryType === "delivery" ? "Delivery" : "Pick Up"}
            </div>
            <div className="card-pharmacy-span">
              {recommended ? "Reliance Family Clinic" : "Other Pharmacies"}
            </div>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <img src={Greater} />
          </div>
        </div>
        <div className="card-options-container">
          {options.map((opt, index) => {
            return (
              <div style={{ display: "flex", gap: "2px" }} key={index}>
                {opt && (
                  <>
                    <img src={Check} /> <div key={index}>{opt}</div>
                  </>
                )}
              </div>
            );
          })}
        </div>
        {children}
      </div>
    </div>
  );
};

FulfilmentCard.prototype = {
  options: PropTypes.array.isRequired,
  deliveryType: PropTypes.oneOf(["delivery", "pickUp"]).isRequired,
  recommended: PropTypes.bool.isRequired,
};

export default FulfilmentCard;
