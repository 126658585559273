/**
 * Created by the.last.mayaki on 04/11/2017.
 */
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as _ from "lodash";
import * as userActions from "../actions/user";
import MainContent from "../components/boxes/MainContent";
import PageHeader from "../components/boxes/PageHeader";
import PageBody from "../components/boxes/PageBody";
import CenteredContentBlock from "../components/boxes/CenteredContentBlock";
import ChangePasswordForm from "../components/forms/ChangePasswordForm";
import LoadingScreen from "../components/LoadingScreen";
import NotificationBanner from "../components/boxes/NotificationBanner";

class ChangePasswordContainer extends React.Component {
  onFormSubmit = (values, dispatch) => {
    dispatch(userActions.setNewPassword(values));
  };

  renderLoading = () => {
    return (
      <MainContent>
        <PageHeader title="Change Password" />
        <PageBody>
          <LoadingScreen />
        </PageBody>
      </MainContent>
    );
  };

  renderMessage = (message) => {
    const { passwordUpdated } = this.props;

    return (
      <MainContent>
        <PageHeader title="Change Password" />
        <PageBody>
          <CenteredContentBlock>
            <h1>Notification!</h1>
            <p style={{ fontSize: "12px" }}>{message}</p>
            <br />
            {passwordUpdated ? (
              <a href="/">Go Back</a>
            ) : (
              <button
                className="button_link"
                onClick={() => window.location.reload()}
              >
                Try Again
              </button>
            )}
          </CenteredContentBlock>
        </PageBody>
      </MainContent>
    );
  };

  renderContent = () => {
    const { passwordUpdated, error } = this.props;
    return (
      <MainContent>
        <PageHeader title="Change Password" />
        <PageBody>
          {passwordUpdated && (
            <NotificationBanner
              type="success"
              message="Password Successfully Updated. Please always endeavour to keep your passwords safe and secure."
              title="Success!"
            />
          )}
          {!_.isEmpty(error) && (
            <NotificationBanner
              type="error"
              message={
                error.message ||
                "Sorry, an error seems to have occurred. Please try again later."
              }
              title="Success!"
            />
          )}
          <div className="dashboard-card form">
            <ChangePasswordForm onSubmit={this.onFormSubmit} />
          </div>
        </PageBody>
      </MainContent>
    );
  };

  render() {
    const { isLoading } = this.props;

    if (isLoading) return this.renderLoading();

    return this.renderContent();
  }
}

const mapStateToProps = (state) => {
  const { isLoading, error, passwordUpdated } = state.user;
  return { isLoading, error, passwordUpdated };
};

export default withRouter(connect(mapStateToProps)(ChangePasswordContainer));
