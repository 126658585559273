import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Input from "../boxes/Input";
import Select from "../boxes/Select";

const ContactDetails = ({
  states,
  onClose,
  MODALNAVIGATOR,
  rfcPharmacies,
  ...props
}) => {
  const contactDetailsSchema = Yup.object().shape({
    state: Yup.string().required("State is required"),

    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(
        /^(\+234)?(0[789]\d{9}|[789]\d{9})$/,
        "Phone number is not valid"
      ),

    alternativePhoneNumber: Yup.string().matches(
      /^(\+234)?(0[789]\d{9}|[789]\d{9})$/,
      "Alternative phone number is not valid"
    ),
    // This field is optional
  });

  const {
    control,
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(contactDetailsSchema),
  });
  const onSubmit = (data) => {
    const isRfcPresent = rfcPharmacies.some(
      (pharmacy) => pharmacy.state_object.name === data.state
    );
    props.setContactDetails({ ...data, isRfcPresent });
    props.setModalContent(MODALNAVIGATOR.fullfillmentOptions);
  };

  return (
    <div>
      <h2 className="text-[#323539] text-2xl font-bold my-6">Prescription</h2>

      <p className="text-lg text-[#383D43] font-bold mb-[2px]">
        Contact details
      </p>

      <form
        className="flex flex-col mt-8 gap-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="state"
          control={control}
          render={({ field }) => {
            return (
              <Select
                label="What state are you currently resident?"
                name="states"
                id="states"
                {...field}
                options={states}
                error={errors.state?.message}
              />
            );
          }}
        />

        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => {
            return (
              <Input
                label="Phone Number"
                name="phone_number"
                id="phone_number"
                placeholder="Enter Phone Number"
                type="tel"
                {...field}
                error={errors.phoneNumber?.message}
              />
            );
          }}
        />

        <Controller
          name="alternativePhoneNumber"
          control={control}
          render={({ field }) => {
            return (
              <div>
                <Input
                  label="Alternative Phone Number"
                  name="alternative_phone_number"
                  id="alternative_phone_number"
                  placeholder="Enter Alternative Phone Number"
                  type="tel"
                  {...field}
                  error={errors.alternativePhoneNumber?.message}
                />
                <p className="text-[#858C95] text-sm mt-4 w-[480px] ">
                  Add a second phone number so we can reach you if the first one
                  is unavailable.
                </p>
              </div>
            );
          }}
        />

        <div className="flex items-center">
          <button
            className="w-1/2 bg-white border border-[#E5E5E7] py-3 rounded-[128px] text-[#323539]"
            type="button"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="w-1/2 bg-[#094063] border border-[#E5E5E7] py-3 rounded-[128px] text-white"
            type="submit"
          >
            Proceed
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactDetails;
