import React from "react";

const Select = ({
  label,
  id,
  name,
  value,
  onChange,
  options,
  required = false,
  disabled = false,
  className = "",
  register,
  error,
  ...props
}) => {
  const hasError = !!error;

  return (
    <div className={`${className}`}>
      {label && (
        <label
          htmlFor={id}
          className="block text-base text-[#323539] font-bold mb-2"
        >
          {label}
        </label>
      )}
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        className={`shadow appearance-none border-[#E5E5E7] border rounded-[128px] w-full py-[14px] px-4  text-base text-[#858C95] focus:outline-none focus:shadow-outline ${
          hasError ? "border-[#EF4444]" : ""
        }`}
        {...props} // Spread remaining props
      >
        <option value="">Select State</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      {hasError && <p className="text-base text-[#EF4444] mt-2">{error}</p>}
    </div>
  );
};

export default Select;
